import { allEnvironmentConfig, customEnvironmentConfig } from "./config"

export const configGettter = {
    isDevMachine: false,
    envConfig: null,
    setEnv: function (hostName) {
        if (hostName === null || hostName === undefined) {
            hostName = window.location.host.split(".")[0];
            hostName = hostName.split(":")[0];
        }
        hostName = hostName.toLowerCase();
        this.isDevMachine = hostName.indexOf("localhost") !== -1;
        this.envConfig = allEnvironmentConfig.find(e => e.hostprefix.find(h => hostName.endsWith(h)));
    },
    getConfig: function () {
        if (customEnvironmentConfig ) {
            return customEnvironmentConfig;
        }
        if (this.envConfig == null) {
            this.setEnv();
        }
        return this.envConfig;
    }
};
