import * as msal from "@azure/msal-browser";
import { configGettter } from "./configGetter";

export const envConfig = configGettter.getConfig();

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [envConfig.scope || "user.read"]
  },
};

export const clearLocalStorage = () => {
  for (const key of Object.keys(localStorage)) {
    if (key.includes('"authority":') ||
        key.startsWith("msal") ||
        key.includes('login')) {
      localStorage.removeItem(key);
    }
  }
};

export const msalApp = new msal.PublicClientApplication({
  auth: {
    clientId: envConfig.clientId,
    authority: envConfig.authorityUrl,
    protocolMode: msal.ProtocolMode.AAD,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  system: {
    tokenRenewalOffsetSeconds: 120 //2 mins
    // logger: {
    //   error: console.error,
    //   errorPii: console.error,
    //   info: console.log,
    //   infoPii: console.log,
    //   verbose: console.log,
    //   verbosePii: console.log,
    //   warning: console.warn,
    //   warningPii: console.warn
    // }
  }
});
