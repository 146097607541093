import { configGettter } from "./configGetter"

const urlInference = {
  noInfer: false,
  infer(inputUrl) {
    try {
      if (window.location.host == null || this.noInfer) return inputUrl;

      var envConfig = configGettter.getConfig();      
      if (configGettter.isDevMachine) {
        return inputUrl; //if it's dev machine return the URL as is.
      }
      
      var inputUrlObj = new URL(inputUrl);
      if (inputUrlObj == null) {
        console.log("inputUrlObj is null::::" + inputUrl);
        return inputUrlObj;
      }

      return inputUrlObj.href.replace(inputUrlObj.hostname, envConfig.ApiGatewayHost);
    } catch (ex) {
      console.error(ex);
      return inputUrl;
    }
  },
  getCdnHostUrl(defaultPath) {
    var envConfig = configGettter.getConfig();
    if (envConfig.isDevMachine && defaultPath) hostName = defaultPath;
    return envConfig.CdnHostUrl;    
  }
};

export default urlInference;
