import axios from "axios";
import AuthProvider from "./AuthProvider";
import { envConfig } from "./authUtils";
import urlInference from "./urlInference";

const httpService = {
  fiscalYear: null,
  extractId: null,
  impersonateAs: null,
  planningCycle: null,
  currentSeason: null,

  setHeaders(httpVerbObj, customHeaders) {
    if (!this.fiscalYear || this.fiscalYear === null) {
      throw "Fiscal Year is not set or invalid, it is mandatory header for APIs!!";
    }

    for (var key in customHeaders) {
      httpVerbObj[key] = customHeaders[key];
    }

    httpVerbObj["fiscalYear"] = this.fiscalYear;
    if (this.planningCycle !== null && this.planningCycle !== undefined) {
      httpVerbObj["planningCycle"] = this.planningCycle;
    }
    if (this.currentSeason) {
      httpVerbObj["currentSeason"] = this.currentSeason;
    }
    if (this.extractId) {
      httpVerbObj["extractId"] = this.extractId;
    }
    if (this.impersonateAs) {
      httpVerbObj["impersonateAs"] = this.impersonateAs;
    }
  },

  async createSvcInstance(method, customHeaders) {
    
    await AuthProvider.acquireToken();

    let service = axios.create({
      headers: { Authorization: "Bearer " + AuthProvider.token }
    });

    let httpVerbObj = null;
    if (method.toLowerCase() === "get") {
      httpVerbObj = service.defaults.headers.get;
    } else if (method.toLowerCase() === "post") {
      httpVerbObj = service.defaults.headers.post;
    } else {
      throw "Unsupported http verb, only get and post are supported!!";
    }

    this.setHeaders(httpVerbObj, customHeaders);
    return service;
  },

  async get(path, headers, parameters, responseType = "json") {
    const httpVerb = "get";
    const service = await this.createSvcInstance(httpVerb, headers);
    path = urlInference.infer(path);
    return service.request({
      method: httpVerb,
      url: path,
      params: parameters,
      responseType: responseType
    });
  },

  async post(path, headers, payload, responseType = "json", parameters = null) {
    const httpVerb = "post";
    const service = await this.createSvcInstance(httpVerb, headers);
    path = urlInference.infer(path);
    return service.request({
      method: httpVerb,
      url: path,
      responseType: responseType,
      data: payload,
      params: parameters
    });
  },

  handleSuccess(response) {
    return response.data;
  },

  handleError(error) {
    if (error.response && error.response !== null) {
      console.log(error.response);
      return Promise.reject(error.response);
    }
    console.log(error);
    return Promise.reject(error);
  }
};

window.httpService = httpService;
window.AuthProvider = AuthProvider;
window.urlInference = urlInference;
window.envConfig = envConfig;
export default httpService;
