import {
  msalApp,
  clearLocalStorage,
  GRAPH_REQUESTS,
} from "./authUtils";

const AuthProvider = {
  name: "",
  alias: "",
  idToken: null,
  accessToken: null,
  signInResponse: null,
  errorObj: null,
  expiresOn: null,

  acquireToken: async function () {
    this.errorObj = null;

    if (this.signInResponse === null) {
      await this.signIn();
      return;
    }

    await msalApp.acquireTokenSilent({
      account: this.signInResponse.account,
      scopes: GRAPH_REQUESTS.LOGIN.scopes
    }).then(loginResponse => {
      this.idToken = loginResponse.idToken;
      this.accessToken = loginResponse.accessToken;
      this.expiresOn = loginResponse.expiresOn;
    }).catch(async (error) => {
      this.errorObj = error;
      console.log(error);
      await this.signIn();
    });
  },

  signIn: async function () {
    this.errorObj = null;
    clearLocalStorage();
    await msalApp
      .loginPopup(GRAPH_REQUESTS.LOGIN)
      .then(loginResponse => {
        this.storeUserDetails(loginResponse);
        return true;
      })
      .catch(error => {
        this.errorObj = error;
        return false;
      });
  },

  storeUserDetails: function (response) {
    this.signInResponse = response;
    this.idToken = response.idToken;
    this.accessToken = response.accessToken;
    this.expiresOn = response.expiresOn;
    if (response.idToken && response.idToken.rawIdToken) {
      this.idToken = response.idToken.rawIdToken;
    }
    this.name = response.account.name;
    // For corp accounts, only the alias is used to store user access.
    // For other non-public clouds, we use the full email address.
    this.alias = this.getUserAlias(response).replace('@microsoft.com', '');
    localStorage.setItem('user', this.name);
    localStorage.setItem('username', this.alias);
  },

  getUserAlias: function (response) {
    if (response.account.idTokenClaims.upn !== undefined && response.account.idTokenClaims.upn.length > 0) {
      return response.account.idTokenClaims.upn;
    }

    if (response.account.idTokenClaims.preferred_username !== undefined && response.account.idTokenClaims.preferred_username.length > 0) {
      return response.account.idTokenClaims.preferred_username;
    }

    if (response.account.username !== undefined && response.account.username.length > 0) {
      return response.account.username;
    }

    throw Error('User Alias not found in token');
  },

  signOut: async function () {
    msalApp.logoutPopup(logoutRequest).then(() => {
      window.location.reload();
    });
  }
};

export default AuthProvider;
